export const findNoveltieCategoryExist = (category, novelties) => {
  let flag = false
  novelties?.forEach((element) => {
    element.novelties_categories.map((noveltyCategory) => {
      if (category === noveltyCategory.name) {
        flag = true
      }
    })
  })
  return flag
}
